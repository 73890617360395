.clinic-page {
	--content-max-width: 45rem;

	.content {
		max-width: var(--content-max-width);
		padding-bottom: $page_padding-vertical;
		@include responsive($breakpoint-tablet) {
			max-width: 100%;
			width: 100%;
		}

		@include responsive($page-breakpoint-mobile) {
			a  button {
				width: 100%;
			}
		}
		li {
			list-style: disc;
		}
	}

	.clinic-dr-pam {
		&.dual-content-section {
			.content {
				margin-right: auto;
				button {
					margin-bottom: 2em;
				}
				.acc-logos-container {
					width: 100%;
					display: flex;
					align-items: flex-start;
					flex-grow: 1;
					flex-shrink: 1;
					gap: $page_padding-horizontal * 1.5;
					margin-bottom: 30px;

					@include responsive($breakpoint-tablet) {
						flex-wrap: wrap;
						justify-content: left;
					}
					@include responsive($page-breakpoint-mobile) {
						justify-content: center;
					}

					img {
						width: auto;
						height: 100px;
					}

					&.with-border {
						gap: $page_padding-horizontal;
						padding-top: $page_padding-vertical;
						margin-top: $page_padding-vertical;
						border-top: 2px solid $color-primary;
					}

					&.small-logos {
						&.with-border {
							margin-top: $page_padding-vertical * 0.75;
							padding-top: $page_padding-vertical * 0.75;
						}
						img {
							height: 50px;
						}
					}
				}
			}
		}
	}

	.accredited-practitioner {
		position: relative;
		--image-width: 480px;
		--image-height: 480px;

		&.dual-content-section {
			display: block;

			img {
				max-width: 100%;
				height: auto;
				object-fit: cover;
			}

			.content {
				margin-left: auto;
			}

			.image-container {
				position: absolute;
				top: 0;
				left: -50px;
				width: var(--image-width);
				height: var(--image-height);
				overflow: hidden;
			}

			@mixin switch-layout {
				display: flex;
				flex-direction: column;
				row-gap: $page-spacing * 2;
				align-items: flex-start;
				.image-container {
					order: -1;
					align-self: center;
				}
			}

			@include responsive(75rem) {
				.image-container {
					--image-width: 200px;
					--image-height: auto;
					position: relative;
					top: auto;
					left: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					&:not(.small-image) {
						--image-width: 200px;
						@include switch-layout;
					}
				}
				display: grid;
				grid-template-columns: 1fr var(--image-width);
				grid-column-gap: $page_padding-horizontal * 3;
				align-items: center;
			}

			@include responsive($breakpoint-tablet) {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				text-align: left;

				.image-container {
					position: relative;
					top: auto;
					left: auto;
					margin-right: 0;
					--image-width: 280px;
					--image-height: auto;

					img {
						width: 80%;
						height: auto;
					}
				}

				.content {
					margin-left: 0;
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}
}
