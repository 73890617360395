.message-box {
	display: flex;
	align-items: center;
	gap: 2rem;
	padding: 1em 0 0;

	&.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.9);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;

		.video-container {
			position: relative;
			width: 50vw;
			height: 40vw;
			@include responsive($breakpoint-tablet) {
				width: 80%;
				height: 80%;
			}
			@include responsive($page-breakpoint-mobile) {
				height: 50%;
			}
			.close-button {
				position: absolute;
				top: 0;
				right: -20px;
				background: none;
				border: none;
			}
		}
	}

	.video-container {
		width: 18vw;
		height: 10vw;
		position: relative;
		border-radius: 1rem;
		overflow: hidden;
		@include responsive(35rem) {
			width: 48vw;
			height: 30vw;
		}
		iframe {
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	.message-link {
		display: flex;
		align-items: center;
		gap: 1rem;

		img {
			width: 30px;
			height: auto;
		}

		&:hover {
			.icon-play {
				content: url('/images/icons/Icon_Play_Hover.svg');
			}
		}
	}
	@include responsive($page-breakpoint-mobile) {
		flex-direction: column;
		align-items: center;
	}
}
