// -----------------------------------------
// Article page used in Free-Resources and Blog
// -----------------------------------------
.article-page {
	.content-container {
		width: 100%;
		padding: 0 $page_padding-horizontal * 2.5;

		@include responsive($breakpoint-tablet) {
			padding: 0;
		}
		.resource-options {
			margin: $page_padding-vertical * 1.5 0;
			display: flex;
			justify-content: center;

			.Button {
				display: flex;
				align-items: center;
				gap: $spacing-sm;
			}
		}
	}

	// -----------------------------------------
	// Finished Section in article page
	// -----------------------------------------

	.finished-section {
		padding: $page-spacing * 1.5;
		display: flex;
		flex-direction: column;
		row-gap: $page-spacing;
		align-items: center;

		.finished-text {
			margin: 0;
			font-family: $font-display-bold-family;
			font-weight: $font-display-bold-weight;
			letter-spacing: $font-body-bold-spacing;
			font-size: $font-size-xl;
			line-height: 1em;
		}
	}

	// -----------------------------------------
	// Filterable Article List section in Blog article page
	// -----------------------------------------

	#article-list {
		padding-top: 0;
		padding-bottom: $page_padding-vertical * 1.5;
		@include responsive($breakpoint-tablet) {
			padding: $page_padding-vertical * 0.5 0 $page_padding-vertical * 1.5 0;
		}

		h2,
		.content {
			text-align: center;

			button {
				margin-top: 1em;
				width: auto;
			}
		}

		.load-more-container {
			margin-bottom: 0;
		}

		.article-load-more {
			background-color: transparent;
			color: $color-primary;
			border: none;
			text-decoration: underline;
		}
	}
}

// -------------------------------------
// Article Content
// -------------------------------------
.ArticleHtml {
	.markdown {
		img {
			border: 0.5rem solid $color-primary;
		}

		p,
		a {
			word-break: break-word;
		}
		> :last-child {
			margin-bottom: 0;
		}

		a {
			color: $color-accent-1;
			&:after {
				border-color: $color-accent-1;
			}
		}

		blockquote {
			background: transparentize($color-primary, 0.9);
			margin: 1.75em 0;
			padding: 1.5em;
		}

		h2 {
			margin: 2.5em 0 1.25em;
		}

		iframe {
			margin: 4em auto;

			@include responsive(42rem) {
				height: 49dvw;
			}

			@include responsive($page-breakpoint-mobile) {
				margin: 2.5em auto;
			}
		}

		ul,
		ol {
			@include responsive($breakpoint-tablet) {
				padding: 0 2em 0 1.2em;
			}
		}

		ul {
			li {
				list-style: disc;
				@include responsive($page-breakpoint-mobile) {
					word-break: break-word;
				}
			}
		}

		ol {
			li {
				list-style: decimal;
			}
		}

		a.rollover {
			color: $color-primary;
			text-decoration: underline dashed $color-primary;
			text-underline-offset: 5px;

			&:hover,
			&:focus,
			&:active {
				&:after {
					display: none;
				}
			}
		}
		.table-wrapper {
			@include scrollbar(5px, $color-primary);
		}
	}
}
