.homepage {
	--vertical-padding: #{$page_padding-vertical * 2};
	--horizontal-padding: #{$page_padding-horizontal * 2};

	section {
		padding: $page_padding-vertical $page_padding-horizontal * 4;
		width: 100dvw;
		max-width: 100%;
		border-bottom: 2px solid $color-white;
		position: relative;
		z-index: 1;

		@include responsive($breakpoint-tablet) {
			padding: $page_padding-vertical * 1 $page_padding-horizontal * 2;
		}
		@include responsive($page-breakpoint-mobile) {
			padding: $page_padding-vertical $page_padding-horizontal * 1;
		}
	}

	.content {
		padding: 0 0 var(--vertical-padding);
	}

	section.hero-section {
		@include responsive($breakpoint-tablet) {
			padding-top: $page_padding-vertical * 2;
		}
	}

	.hero-section {
		min-height: 100dvh;
		position: relative;
		background-image: url('/images/backgrounds/pam-baby-mother_on_deck.avif');
		background-size: cover;
		background-position: center;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		@include overlay($opacity: 0.8);

		.hero-content {
			position: relative;
			z-index: 2;
			margin: $page_padding-vertical * 1.5 $page_padding-horizontal * 3 0;
			text-align: center;

			> p {
				margin-left: $page_padding-horizontal * 2.5;
				margin-right: $page_padding-horizontal * 2.5;
			}

			.scroll-arrow {
				.arrow-down {
					transition: transform 0.3s ease;
				}
				&:hover {
					.arrow-down {
						transform: scale(1.2);
					}
				}
			}
		}
		@include responsive($breakpoint-tablet) {
			.hero-content {
				margin: 0;
				> p {
					margin-left: $page_padding-horizontal * 1;
					margin-right: $page_padding-horizontal * 1;
				}

				.scroll-arrow {
					margin-top: 1rem;
				}
			}
		}
		@include responsive($page-breakpoint-mobile) {
			@include overlay($opacity: 0.9);
			background-position: 30% center;
			.hero-content {
				> p {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	// -----------------------------------------
	// Program Section
	// -----------------------------------------

	$program-card-gap: 4rem;
	$program-card-height-large: 360px;
	$program-card-border-radius-large: $program-card-height-large / 2;

	.programs-section {
		text-align: center;

		h2 {
			text-align: center;
		}

		.programs-group {
			margin-bottom: $program-card-gap;
			align-items: center;
			.icons-row {
				display: flex;
				justify-content: space-between;
				margin-bottom: $program-card-gap;
				gap: 1.5rem;

				div {
					display: flex;
					align-items: center;
					text-align: left;
					max-width: 280px;

					svg {
						margin-right: 0.8rem;
						flex-shrink: 0;
					}

					span {
						line-height: 1.5;
					}
				}
				@include responsive($page-breakpoint-desktop) {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					gap: 1.5rem;
					margin: 2rem 0;

					div {
						flex-direction: row;
						justify-content: center;
						align-items: center;
						max-width: 350px;
						text-align: left;
					}
				}
			}
		}

		--program-card-direction: row;
		--program-card-width: 100%;

		@include responsive($breakpoint-tablet) {
			--program-card-direction: column;
			--program-card-width: 80%;
		}

		@include responsive($page-breakpoint-mobile) {
			--program-card-width: 100%;
		}

		.program-card {
			display: flex;
			flex-direction: var(--program-card-direction);
			width: var(--program-card-width);
			margin: 0 auto $program-card-gap;
			overflow: hidden;
			background-color: currentColor;
			height: $program-card-height-large;
			border-radius: $program-card-border-radius-large;

			.program-left,
			.program-right {
				flex: 1;
			}

			.program-left {
				position: relative;
				background-size: cover;
				background-position: center;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: $page-spacing;
				img {
					z-index: 2;
					width: 250px;
					height: auto;
				}
			}

			.program-right {
				padding: $page_padding-vertical $page_padding-horizontal * 3.5 $page_padding-vertical
					$page_padding-horizontal;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				color: $color-white;
				.large-text {
					font-size: 1.5rem;
					margin-bottom: 1rem;
				}

				.button {
					align-self: flex-start;
				}
			}
		}

		@include responsive($breakpoint-tablet) {
			.program-card {
				height: auto;
				border-radius: 0;
				border-top-left-radius: 250px;
				border-top-right-radius: 250px;
				border-bottom-left-radius: $program-card-border-radius-bottom;
				border-bottom-right-radius: $program-card-border-radius-bottom;

				.program-right {
					padding: $page_padding-vertical $page_padding-horizontal;
					p {
						text-align: center;
					}
					.button {
						align-self: center;
					}
				}
				.program-left {
					padding-top: $page_padding-vertical * 2;
					padding-bottom: $page_padding-vertical * 2;
				}
			}
		}

		@include responsive(30rem) {
			.program-card {
				width: 100%;
				.program-right {
					padding: $page_padding-vertical $page_padding-horizontal * 0.5;
				}
				.program-left {
					padding-top: $page_padding-vertical * 1.5;
					padding-bottom: $page_padding-vertical * 1.5;

					img {
						width: 200px;
						height: auto;
					}
				}
			}
		}

		.ndc .program-left {
			background-image: url('/images/accent-photos/health-professional.avif');
			@include overlay(rgba(117, 87, 115), 0.6);

			@include responsive($breakpoint-tablet) {
				background-position: center 45%;
			}
		}

		.psp .program-left {
			background-image: url('/images/accent-photos/baby-sleeping.webp');
			@include overlay(rgba(64, 110, 142), 0.6);
		}

		.bsb .program-left {
			background-image: url('/images/accent-photos/breastfeeding.avif');
			@include overlay(rgba(157, 114, 121), 0.6);
		}

		.ndc {
			color: $color-ndc-chineseViolet;
		}

		.psp {
			color: $color-psp-blueDark;
		}

		.bsb {
			color: $color-bsb-copperRose;
		}
	}

	// -----------------------------------------
	// Evidence Based Section
	// -----------------------------------------

	.evidence-based-section {
		$image-width: 55dvw;
		display: grid;
		column-gap: 2dvw;
		flex-direction: column;
		position: relative;
		z-index: 0;

		&.image-left {
			grid-template-columns: $image-width 1fr;
			grid-template-areas: 'image content';
			padding-left: 0;
			padding-right: $page_padding-horizontal * 4;
			.image {
				mask-position: right center;
			}
		}

		.image {
			grid-area: image;
			align-self: stretch;
			position: relative;
			overflow: hidden;
			top: -20%;
			img {
				width: auto;
				height: 100%;
			}
		}
		@include responsive($page-breakpoint-desktop) {
			display: block;
			overflow-x: hidden;

			&.image-left {
				padding-left: $page_padding-horizontal * 4;
				padding-right: $page_padding-horizontal * 4;
			}
			.image {
				position: absolute;
				transform: scaleX(-1);
				right: -10%;
				top: 0;
			}
		}
		@include responsive($breakpoint-tablet) {
			&.image-left {
				padding-left: $page_padding-horizontal * 2;
				padding-right: $page_padding-horizontal * 2;
			}
		}
		@include responsive($page-breakpoint-mobile) {
			&.image-left {
				padding-left: $page_padding-horizontal * 1;
				padding-right: $page_padding-horizontal * 1;
			}
			.image {
				right: -40%;
			}
		}

		.content-evidence {
			@include responsive($page-breakpoint-desktop) {
				max-width: 60%;
				position: relative;
				margin: 0;
				z-index: 2;
			}
			@include responsive($breakpoint-tablet) {
				max-width: 100%;
			}
		}

		.testimonials {
			grid-column: 1 / -1;
			display: flex;
			flex-direction: row;
			gap: 4rem;
			padding-left: $page_padding-horizontal * 4;

			@include responsive($page-breakpoint-desktop) {
				padding-left: 0;
			}

			@include responsive(55rem) {
				flex-direction: column;
			}

			.testimonial {
				flex: 1;
				padding: 20px 20px 0;
				text-align: left;

				@include responsive(55rem) {
					text-align: center;
				}

				> p {
					margin-bottom: 0;
					padding: 10px 10px 0;

					@include responsive($page-breakpoint-mobile) {
						padding: 0;
					}
				}
			}
		}
	}

	// -----------------------------------------
	// Dr Pam Section
	// -----------------------------------------

	.dual-content-section {
		position: relative;
		.image-container-light-bg {
			@include responsive($page-breakpoint-mobile) {
				margin-top: 3em;
			}
		}
		@include responsive($page-breakpoint-mobile) {
			h2 {
				margin-top: 0;
			}
		}
		.social-links {
			display: flex;
			gap: 30px;
			margin-top: 2.5rem;
			@include responsive($page-breakpoint-mobile) {
				flex-direction: column;
			}

			a {
				display: flex;
				align-items: center;
				gap: 10px;
				text-decoration: none;

				span {
					font-size: 0.85em;
					font-weight: 500;
				}
			}
		}
	}
}
