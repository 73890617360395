.our-approach {
	.container-section {
		padding-top: 1rem;
		.container {
			border-bottom: 3px dotted rgba(255, 255, 255, 0.3);
			padding: 10px 0;

			.container-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				cursor: pointer;

				h1 {
					font-family: $font-body-normal-family;
					font-weight: 200;
					margin: 0;
					font-size: 3rem;
					@include responsive($page-breakpoint-mobile) {
						font-size: 1.8rem;
					}
				}
				img {
					margin-left: $page_padding-horizontal;
					transition: transform 0.3s ease;

					&.rotating {
						transform: rotate(180deg);
					}
					@include responsive(75rem) {
						margin-bottom: auto;
					}
					@include responsive($page-breakpoint-mobile) {
						width: 32px;
						height: 32px;
					}
				}
			}

			.container-content {
				overflow: hidden;
				opacity: 0;
				height: 0;
				transition: height 0.4s ease-in-out, opacity 0.3s ease-in-out,
				  padding 0.3s ease-in-out;

				&.open {
					height: auto;
					opacity: 1;
					padding: 2rem 0;
				}

				.text-image {
					display: flex;
					align-items: flex-start;
					gap: 2rem;

					.image {
						position: relative;
						flex-shrink: 0;
						margin-left: auto;
						width: 40%;
						max-width: 500px;

						img {
							border-radius: 20px;
							width: 100%;
							height: 250px;
							object-fit: cover;
						}
					}
				}
				@include responsive(55rem) {
					.text-image {
						flex-direction: column;
						align-items: center;
						gap: 0;
						p {
							font-style: italic;
						}
						.image {
							width: 100%;
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
