$page-spacing: 2rem;
$breakpoint-large-desktop: 90rem;
$button-size: 3rem;
$column-gap: $page-spacing * 2;

.testimonial-section {
	--testimonial-quote-count: 3;

	@include responsive($breakpoint-large-desktop, min) {
		--testimonial-quote-count: 3;
	}
	@include responsive($breakpoint-large-desktop) {
		--testimonial-quote-count: 2;
	}
	@include responsive($breakpoint-tablet) {
		--testimonial-quote-count: 1;
		min-height: unset;
	}

	.testimonial-container {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 0;
		z-index: 2;
		position: relative;
	}

	.testimonial-carousel {
		display: flex;
		overflow-x: auto;
		scroll-behavior: smooth;
		-ms-overflow-style: none;
		scrollbar-width: none;
		gap: $column-gap;
		position: relative;
		flex: 1 1 auto;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.testimonial-item {
		flex: 0 0
			calc((100% - (#{$column-gap} * (var(--testimonial-quote-count) - 1))) / var(--testimonial-quote-count));
		flex-direction: column;
		padding: $page-spacing $page-spacing 0;
		gap: $page-spacing;
		display: flex;

		> * {
			margin: 0;
		}

		.testimonial-stars {
			display: flex;
			justify-content: center;
			gap: 0.75em;
			svg {
				height: 1.75em;
			}
		}

		.testimonial-text {
			font-size: 1rem;
		}

		.testimonial-author {
			font-size: 0.9rem;
			.name {
				font-weight: 700;
				letter-spacing: 0.03em;
			}
			.location {
				padding-left: 0.15em;
			}
		}
	}
}
