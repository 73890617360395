@import '@dr-pam/common-components/Styles/Themes/drpam';

body,
html {
	background: $color-primary;
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
	font-size: 14px;
	font-family: $font-body-normal-family;
}

html {
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.navbar + * {
	flex-grow: 1;
	flex-shrink: 0;
}

// ------------------------------------------------------------------
// Variables
// ------------------------------------------------------------------

$page_padding-horizontal: $spacing-xxl;
$page_padding-vertical: calc($spacing-xxl * 1.5);

// Cards border radius used in home page
$program-card-border-radius-bottom: 20px;
$program-card-border-radius-small: 150px;

* {
	--approx-menu-height: 6rem;
}

// ------------------------------------------------------------------
// Backgrounds
// ------------------------------------------------------------------

.dark-bg {
	background-color: $color-primary;
	color: $color-secondary;
	a {
		color: inherit;
		&:after {
			border-color: $color-secondary;
		}
	}
	.emphasised-text {
		color: $color-brand-1;
	}
}

.light-bg {
	background-color: $color-white;
	color: $color-text-dark;
	a {
		color: inherit;
	}
}

header {
	background: $color-primary;
	color: $color-secondary;
}

@import 'abstracts/abstracts';
@import 'components/components';
@import 'page-layout/page-layout';
