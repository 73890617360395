.book-page {
	padding-top: var(--navbar-height_approx);
	.book-item {
		display: flex;
		gap: 6rem;
		padding: $page_padding-vertical * 2 $page_padding-horizontal * 5;
		align-items: center;
		img {
			width: 250px;
			height: auto;
			object-fit: cover;
		}

		.book-details {
			flex: 1;

			.book-actions {
				display: flex;
				gap: 2rem;
				margin: 3rem 0 0;
				align-items: center;
				@include responsive(82rem) {
					flex-direction: column;
					align-items: flex-start;
				}
				@include responsive($breakpoint-tablet) {
					align-items: center;

					a:nth-child(2) .button {
						background-color: transparent;
						color: $color-primary;
						border: 2px solid $color-primary;
					}
				}
			}
		}
	}

	.divider {
		border-top: 2px solid $color-primary;
		margin: 2rem 0;
	}
	@include responsive($breakpoint-tablet) {
		.book-item {
			flex-direction: column;
			gap: 2rem;
			padding: $page_padding-vertical * 2 $page_padding-horizontal * 2;
			align-items: center;
			position: relative;
			img {
				width: 200px;
				height: auto;
				object-fit: cover;
			}
			p {
				text-align: left;
			}

			h3 {
				text-align: center;
				margin: 2rem 0;
			}

			.book-actions {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				align-items: center;
			}
		}
	}
	@include responsive($page-breakpoint-mobile) {
		.book-item {
			padding: $page_padding-vertical * 1 $page_padding-horizontal * 1;
		}
	}
}
