:root {
	--navbar-height_approx: 6.55rem;
	@include responsive(66rem) {
		--navbar-height_approx: 6rem;
	}
}

// -----------------------------------------
// General text
// -----------------------------------------

p,
li {
	line-height: 1.8rem;
}

p {
	margin: 0 0 2em;
	.emphasised-text {
		color: $color-accent-1;
	}
}

.no-list-style {
	list-style: none;
}

h1,
h2,
h3,
h4 {
	font-weight: 600;
	letter-spacing: $font-body-bold-spacing;
	margin: 2em 0 1em;
}
h3 {
	margin: 0;
	padding-bottom: 1em;
}

.emphasised-text {
	color: $color-accent-1;
	&:after {
		border-color: $color-accent-1;
	}
}

.highlight {
	color: $color-brand-2;
}

.large-text {
	font-size: 1.2em;
}

// -----------------------------------------
// General content page styles
// -----------------------------------------

.content-body {
	padding-top: var(--navbar-height_approx);
	border-bottom: 2px solid $color-white;
	color: $color-primary;

	section {
		padding: $page_padding-vertical * 1 $page_padding-horizontal * 4 $page_padding-vertical * 2;

		@include responsive($breakpoint-tablet) {
			padding: $page_padding-vertical * 1 $page_padding-horizontal * 2;
		}

		@include responsive($page-breakpoint-mobile) {
			padding: $page_padding-vertical * 1 $page_padding-horizontal * 1;
		}

		> *:first-child,
		.ArticleHtml > *:first-child {
			margin-top: 2em;
		}
	}
}

.section-width {
	width: 85dvw;
	display: flex;
	flex-direction: column;
	justify-content: center;

	margin: 0 auto;

	> p {
		text-align: center;
	}
	button {
		text-align: left;
	}

	@include responsive($page-breakpoint-desktop) {
		width: 100dvw;
		> p {
			padding: 1rem $page_padding-horizontal * 1.5 0;
		}
	}
}

.dual-content-section {
	--image-width: 400px;
	display: grid;
	grid-column-gap: $page_padding-horizontal * 3;
	grid-template-columns: 1fr var(--image-width);
	&.left-aligned-image {
		grid-template-columns: var(--image-width) 1fr;
	}
	align-items: center;

	.image-container {
		width: var(--image-width);
		height: var(--image-width);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.image-container-dark-bg {
		@include image-container-bg('/images/backgrounds/circle-shape-dark.png');
	}

	.image-container-light-bg {
		@include image-container-bg('/images/backgrounds/circle-shape-light.png');
	}

	@mixin switch-layout {
		display: flex;
		flex-direction: column;
		row-gap: $page-spacing * 2;
		align-items: flex-start;
		.image-container,
		.image-container-dark-bg,
		.image-container-light-bg {
			order: -1;
			align-self: center;
		}
	}

	@include responsive(75rem) {
		--image-width: 200px;
		&:not(.small-image) {
			--image-width: 250px;
			@include switch-layout;
		}
	}

	@include responsive($breakpoint-tablet) {
		h2 {
			margin-top: 0;
		}
		--image-width: 200px;
		&.small-image {
			--image-width: 200px;
			@include switch-layout;
		}
	}
}

// -----------------------------------------
// Links
// -----------------------------------------

a {
	position: relative;
	word-break: auto-phrase;
	overflow-wrap: normal;
	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 0;
		left: 0;
		bottom: -0.5rem;
		border-bottom: 1.5px solid $color-primary;
		transition: width 0.5s ease-in-out;
	}
	&.no-underline {
		text-decoration: none;
		&:after {
			display: none;
		}
	}
	@include active-state {
		cursor: pointer;
		&:after {
			width: 100%;
		}
	}

	&.button {
		&::after {
			content: unset;
		}
	}
}

.custom-link {
	color: unset;
	text-decoration: underline;
	.arrow-icon {
		width: 1.2rem;
		height: auto;
	}
	&:after {
		display: none;
	}
	&:hover {
		.arrow-icon {
			content: url('/images/icons/Link_Arrow-Hover.svg');
		}
	}
}

// -----------------------------------------
// Button
// -----------------------------------------

button {
	text-transform: uppercase;
}

.button {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 16px;
		height: 16px;
	}
	.btn-right-arrow {
		margin-left: 10px;
	}
	.btn-mail {
		margin-right: 10px;
	}
	@include responsive($page-breakpoint-mobile) {
		width: 100%;
	}
}

$gap-space: 2rem;
.carousel-controls {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: $gap-space;
	margin-top: 2rem;
	.carousel-button {
		background-color: transparent;
		color: $color-white;
		border: none;
		cursor: pointer;
		transition: background-color 0.3s ease;

		img {
			display: block;
			transition: transform 0.3s ease;
		}

		&:hover {
			img {
				transform: scale(1.2);
			}
		}
	}
}

// -----------------------------------------
// Quotes
// -----------------------------------------

.quote-style {
	&:before,
	&:after {
		content: '';
		height: 3rem;
		width: 3rem;
	}
}
