$research-gap: 2rem;
$button-size: 3rem;

.research-section {
	position: relative;
	--research-item-count: 3;

	@include responsive($breakpoint-large-desktop) {
		--research-item-count: 3;
	}
	@include responsive(74rem) {
		--research-item-count: 2;
	}
	@include responsive(50rem) {
		--research-item-count: 1;
	}

	.research-carousel {
		display: flex;
		gap: $research-gap;
		overflow-x: auto;
		scroll-behavior: smooth;
		scrollbar-width: none;
		scroll-snap-type: x mandatory;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		.research-block {
			flex: 0 0 calc((100% - (#{$research-gap} * (var(--research-item-count) - 1))) / var(--research-item-count));
			scroll-snap-align: start;
			background-color: $color-brand-1;
			padding: 2rem;
			border-radius: 2rem;
			display: flex;
			flex-direction: column;
			
			.blockquote {
				font-style: italic;
			}

			a {
				display: inline-flex;
				align-items: center;
				margin-top: auto;
				gap: 0.5rem;

				img {
					width: 30px;
					height: 30px;
				}
			}
		}
	}

	@include responsive(74rem, min) {
		.carousel-controls {
			display: none;
		}
	}
}
