.scroll-indicator {
	display: none;
}
@include responsive($page-breakpoint-mobile) {
	.scroll-indicator {
		display: flex;
		align-items: center;
		font-size: 0.8rem;
		color: $color-brand-2;
		justify-content: flex-start;
		width: 100%;
		padding-bottom: $page_padding-vertical * 0.5;
		svg {
			height: 16px;
			width: 16px;
		}
	}
}

.filter-buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;

	button {
		display: inline-flex;
		align-items: center;
		width: auto;
		text-transform: lowercase;
		.close-icon {
			display: inline-block;
			margin-left: 0.5rem;
		}
		&.active {
			background-color: $color-primary;
			color: $color-white;
			border-color: $color-primary;
		}

		&:not(.active) {
			background-color: transparent;
			color: $color-primary;
			border-color: $color-primary;
		}
	}
	@include responsive($page-breakpoint-mobile) {
		flex-wrap: nowrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		gap: 0.5rem;

		scrollbar-width: none;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			display: none;
		}

		button {
			flex: 0 0 auto;
		}
	}
}

.article-list {
	padding: 0;
	width: 100%;
	@include responsive($breakpoint-tablet) {
		padding-bottom: $page_padding-vertical * 0.5;
	}

	li {
		display: flex;
		align-items: stretch;
		background-color: $color-primary;
		margin-bottom: 1rem;

		&:hover {
			background-color: $color-brand-1;
		}
		&:last-child {
			margin-bottom: 0;
		}
		.article-content {
			color: $color-white;
			flex: 1;
			width: 100%;
			z-index: 2;
			a {
				display: flex;
				flex-direction: column;
				font-size: 1.2rem;
				font-weight: 600;
				margin: 1rem $page_padding-horizontal;
				position: relative;
			}

			.article-meta {
				display: flex;
				gap: 1rem;
				font-size: 0.8rem;
				font-weight: 500;
				margin-top: 0.5rem;
			}

			.subcategory-name {
				font-size: 0.8rem;
				font-style: italic;
				font-weight: 400;
			}
		}

		.article-banner {
			flex: 0 0 auto;
			background-size: cover;
			background-position: center;
			width: 250px;
			height: auto;
			top: 0;
			right: 0;

			-webkit-mask-image: linear-gradient(to right, transparent, $color-primary 100%, $color-primary 100%);
			mask-image: linear-gradient(to right, transparent, $color-primary 100%, $color-primary 100%);
		}

		@include responsive($article-list-breakpoint-mobile) {
			flex-direction: column;
			margin-bottom: 1.5rem;
			.article-banner {
				order: -1;
				width: 100%;
				height: auto;
				aspect-ratio: 16/5;
				margin: 0;
				-webkit-mask-image: none;
				mask-image: none;
			}
		}
	}
}
.load-more-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: $page_padding-vertical * 1 $page_padding-horizontal 0;
	@include responsive($breakpoint-tablet) {
		margin: 0 $page_padding-horizontal $page_padding-vertical;
		button {
			width: auto;
		}
	}
}

@include responsive($breakpoint-tablet) {
	.blog-page,
	#article-list {
		.filter-buttons,
		.scroll-indicator,
		h2,
		h3 {
			padding-left: $page_padding-horizontal;
			padding-right: $page_padding-horizontal;
		}
	}
}
