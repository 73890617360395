$mini-program-gap: 0.6rem;
$button-size: 3rem;

.mini-programs {
	--mini-program-count: 6;

	@include responsive(90rem) {
		--mini-program-count: 6;
	}
	@include responsive(85rem) {
		--mini-program-count: 5;
	}
	@include responsive(74rem) {
		--mini-program-count: 4;
	}
	@include responsive(55rem) {
		--mini-program-count: 3;
	}
	@include responsive(45rem) {
		--mini-program-count: 2;
	}
	@include responsive(28rem) {
		--mini-program-count: 1;
	}

	.mini-programs-grid {
		display: flex;
		gap: $mini-program-gap;
		overflow-x: auto;
		scroll-behavior: smooth;
		scrollbar-width: none;
		scroll-snap-type: x mandatory;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		.mini-program-card {
			flex: 0 0
				calc((100% - (#{$mini-program-gap} * (var(--mini-program-count) - 1))) / var(--mini-program-count));
			scroll-snap-align: start;
			background-color: $color-brand-1;
			border-top-left-radius: $program-card-border-radius-small;
			border-top-right-radius: $program-card-border-radius-small;
			border-bottom-left-radius: $program-card-border-radius-bottom;
			border-bottom-right-radius: $program-card-border-radius-bottom;
			overflow: hidden;

			.image-container {
				position: relative;
				width: 100%;
				height: 150px;
				overflow: hidden;
				background-color: transparent;

				@include overlay($opacity: 0.5);

				&:hover::before {
					opacity: 0;
					transition: opacity 0.3s ease;
				}

				.program-image {
					object-fit: cover;
					width: 100%;
					height: 100%;
					transition: transform 0.3s ease;

					&:hover {
						transform: scale(1.05);
					}
				}
			}

			.program-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: -25px auto 10px;
				z-index: 2;
				position: relative;
			}

			h3 {
				margin: 10px 0;
				padding: 0 10px;
				text-align: center;
			}
		}
	}

	@include responsive(85rem, min) {
		.carousel-controls {
			display: none;
		}
	}
}
